<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import { useLocalization } from '/~/composables/localization'

const { translate } = useLocalization()

defineProps<{
  isAcceptingAgreement: boolean
  terms: string
}>()

const emit = defineEmits<{
  (event: 'back'): void
  (event: 'accept'): void
}>()

const agreementTextRef = ref<HTMLElement | null>(null)
const isAgreeButtonEnabled = ref(false)

function onBack() {
  emit('back')
}

function onAccept() {
  emit('accept')
}

function checkAgreementScroll(event: Event) {
  const target = event.target as HTMLElement

  isAgreeButtonEnabled.value =
    Math.ceil(target.scrollTop) >= target.scrollHeight - target.offsetHeight
}

onMounted(() => {
  agreementTextRef.value?.addEventListener('scroll', checkAgreementScroll)
})

onUnmounted(() => {
  agreementTextRef.value?.removeEventListener('scroll', checkAgreementScroll)
})
</script>

<template>
  <base-aside-page :title="`${translate('tpu.authorised')} User Agreement`">
    <div ref="agreementTextRef" class="markup overflow-auto" v-html="terms" />
    <div class="grid grid-cols-2 gap-3 pt-4">
      <base-button
        look="outlined-color"
        :disabled="isAcceptingAgreement"
        class="!rounded"
        @click="onBack"
      >
        Back
      </base-button>
      <base-button
        :loading="isAcceptingAgreement"
        :disabled="isAcceptingAgreement || !isAgreeButtonEnabled"
        class="!rounded"
        @click="onAccept"
      >
        I agree
      </base-button>
    </div>
  </base-aside-page>
</template>
