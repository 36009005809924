<script setup lang="ts">
import Vue, { ref } from 'vue'
import { useRouter, useRoute } from 'vue-router/composables'
import ThirdPartyUserAgreementPure from '/~/components/third-party-user/third-party-user-agreement.pure.vue'
import { useCms } from '/~/composables/cms/use-cms'
import {
  THIRD_PARTY_USER_ROUTE_PREFIX,
  useThirdPartyUsers,
} from '/~/composables/third-party-users'

const router = useRouter()
const route = useRoute()
const { updateThirdPartyEngagementAgreement } = useThirdPartyUsers()
const { terms } = useCms()

const isAcceptingAgreement = ref(false)

function onBack() {
  router.back()
}

async function onAccept() {
  if (isAcceptingAgreement.value) {
    return
  }

  isAcceptingAgreement.value = true

  try {
    await updateThirdPartyEngagementAgreement(true)
  } catch (error: any) {
    Vue.notify({
      type: 'error',
      text: error.message || 'Something went wrong, please try again later',
    })
  } finally {
    isAcceptingAgreement.value = false
  }

  return router.replace(
    route.params.intendedUrl || {
      hash: `${THIRD_PARTY_USER_ROUTE_PREFIX}-permissions`,
    }
  )
}
</script>

<template>
  <third-party-user-agreement-pure
    :terms="terms"
    :is-accepting-agreement="isAcceptingAgreement"
    @back="onBack"
    @accept="onAccept"
  />
</template>
